<template>
  <tracking-layout />
</template>

<script>
import TrackingLayout from "../layouts/TrackingLayout.vue";
export default {
  name: "Tracking",
  components: {
    TrackingLayout
  }
};
</script>
