<template>
  <v-list id="menu-document-list">
    <v-list-item v-for="(doc, index) in documents" :key="index">
      <a :href="download(doc.link)" target="_blank">
        <v-list-item-title>{{
          getLabel("mlstring", {}, doc.title, $i18n)
        }}</v-list-item-title>
      </a>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      documents: [
        {
          title: {
            en: "System Development Lifecycle",
            fr: "Cycle de vie de l'application"
          },
          link: {
            en: "ARONE - SPIRAL - SystemDevelopmentLifecycle.pdf",
            fr: "ARONE - SPIRAL - SystemDevelopmentLifecycle.pdf"
          }
        },
        {
          title: {
            en: "Security Insurance Plan",
            fr: "Plan d’Assurance Sécurité"
          },
          link: { en: "PAS__ARONE_0.91.pdf", fr: "PAS__ARONE_0.91.pdf" }
        }
      ]
    };
  },
  methods: {
    download(link) {
      link = this.getLabel("mlstring", {}, link, this.$i18n);
      return `\\doc/quality/${link}`;
    }
  }
};
</script>

<style lang="scss">
#menu-document-list a {
  color: #000 !important;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  transition: color 0.2s ease-in;

  &:hover {
    color: #0081ff !important;
  }
}
</style>
