<template>
  <div>
    <center-app-bar></center-app-bar>
    <v-main app class="col mt-12">
      <div class="d-inline-flex relative col px-0">
        <v-container class="horizontal-container rounded-lg col-12">
          <track-nav-bar></track-nav-bar>
          <router-view />
        </v-container>
      </div>
    </v-main>
  </div>
</template>

<script>
import CenterAppBar from "../components/center/CenterAppBar.vue";
import TrackNavBar from "../components/tracking/TrackNavBar.vue";

export default {
  components: {
    CenterAppBar,
    TrackNavBar
  }
};
</script>

<style>
.container {
  background: #323853 !important;
  height: 100% !important;
  max-width: 1440px;
}

.v-application--wrap {
  height: 100% !important;
  background: #323853 !important;
}
</style>
