<template>
  <vue-navigation-bar :options="navTrackingOptions" class="mr-0">
    <template v-slot:custom-section>
      <div class="custom-section">
        <v-menu offset-y id="menu-document" class="my-5">
          <template v-slot:activator="{ on, attrs }">
            <a class="vnb__menu-options__option__link" v-bind="attrs" v-on="on">
              <i class="mdi mdi-book"></i>
              Documents
            </a>
          </template>
          <quality-document-list></quality-document-list>
        </v-menu>
      </div>
    </template>
  </vue-navigation-bar>
</template>

<script>
import { mapGetters } from "vuex";
import QualityDocumentList from "./QualityDocumentList.vue";

export default {
  components: { QualityDocumentList },
  computed: {
    ...mapGetters("navigation", ["navTrackingOptions"])
  }
};
</script>

<style lang="scss">
.vnb__menu-options--left {
  margin-right: 1em;
}

.custom-section i {
  margin-right: 5px;
}

.custom-section a {
  color: #000 !important;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  transition: color 0.2s ease-in;

  &:hover {
    color: #0081ff !important;
  }
}

.vnb {
  &__menu-options {
    &__option {
      &__link {
        color: #000 !important;
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff !important;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }
        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: rgba(0, 0, 0, 0.562) !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}

.vnb__popup__bottom__custom-section a {
  padding: 12px 24px;
  color: #0081ff !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: color 0.2s ease-in, background 0.2s ease-in, border 0.2s ease-in;
  border-left: 2px solid #fff;
  width: 100%;

  svg {
    fill: #0081ff !important;
  }

  &:hover {
    color: #333 !important;
    text-decoration: none;
    background: #f3f3f3;
    border-left: 2px solid #007aff;
  }
}
</style>
